<script setup>

</script>

<template>
  <div style="height: 100%; width: 100%; margin-top: 15px;">
    <div style="margin-bottom: 5px;width: 100%">
      <el-input v-model="codeQry" placeholder="请输入运单号" suffix-icon="el-icon-search"
                style="width: 200px;margin-left: 10px"
                @keyup.enter.native="loadPost"></el-input>

      <el-select v-model="orderModel" clearable placeholder="请选择运单状态" style="margin-left: 30px;">
        <el-option
            v-for="item in orderModels"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      <el-date-picker
          v-model="startTime"
          type="datetime"
          placeholder="选择开始时间"
          default-time="00:00:00" style="margin-left: 30px">
      </el-date-picker>

      <span>-</span>
      <el-date-picker
          v-model="endTime"
          type="datetime"
          placeholder="选择结束时间"
          default-time="00:00:00">
      </el-date-picker>
      <el-button style="margin-left: 30px;" type="primary" @click="queryList">查询</el-button>
      <el-button type="success" @click="resertQuery">重置</el-button>
    </div>

    <div style="margin-bottom: 10px;width: 100%; margin-top: 10px;">

      <el-button type="success" @click="offlineUploadFileDialogVisible = true">导入线下订单</el-button>
      <el-button type="success" @click="exportExcelOffline">线下订单模板文件</el-button>
      <el-divider direction="vertical" border-style="dashed" />
      <el-button type="primary" @click="uploadFileDialogVisible = true">导入物流状态</el-button>
      <el-button type="primary" @click="exportExcel">物流状态模板文件</el-button>
    </div>

    <el-table :data="tableData" style="height: 100%; width: 100%"
              :header-cell-style="{background: '#f3f6fd', color: '#555'}"
              border
    >
      <el-table-column prop="code" label="运单号" width="100">
      </el-table-column>
      <el-table-column prop="goodsName" label="商品名" width="240">
      </el-table-column>
      <el-table-column prop="num" label="数量" width="80">
      </el-table-column>
      <el-table-column prop="trustCompany" label="发货公司" width="180">
      </el-table-column>
      <el-table-column prop="trustTime" label="提货日期" width="90">
      </el-table-column>
      <el-table-column prop="receiveAddr" label="收货地址" width="240">
      </el-table-column>
      <el-table-column prop="estimatedArvtime" label="预计送达日期" width="90">
      </el-table-column>
      <el-table-column prop="orderModel" label="运单状态" width="77" >
        <template slot-scope="scope" >
          <el-tag
              :type="getStatusInfoObject(scope.row.orderModel).color"
              disable-transitions>
            {{getStatusInfoObject(scope.row.orderModel).name}}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="operate" label="操作" width="210">
        <template slot-scope="scope">
          <el-button size="mini" type="success" @click="orderDetail(scope.row)">详情</el-button>
          <el-button size="mini" type="primary" @click="updateStatus(scope.row)">修改</el-button>
          <el-button size="mini" type="danger" @click="delStatus(scope.row)">删除</el-button>

        </template>
      </el-table-column>


    </el-table>
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[5, 10, 20, 30]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>

    <el-dialog
        title="运单详情"
        :visible.sync="centerDialogVisible"
        width="60%"
        center>
      <template>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="运单信息" name="first" :model="form">
            <el-descriptions class="margin-top" :column="3" :size="size" border>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-user"></i>
                  订单号
                </template>
                {{ form.code }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-mobile-phone"></i>
                  商品名称
                </template>
                {{ form.goodsName }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-location-outline"></i>
                  数量
                </template>
                {{ form.num }}
              </el-descriptions-item>
              <el-descriptions-item :model="form">
                <template slot="label">
                  <i class="el-icon-user"></i>
                  发货人
                </template>
                {{ form.trustMan }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-mobile-phone"></i>
                  发货人电话
                </template>
                {{ form.trustMobile }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-location-outline"></i>
                  发货公司
                </template>
                {{ form.trustCompany }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-office-building"></i>
                  发货地址
                </template>
                {{ form.trustAddr }}
              </el-descriptions-item>
              <br/>
              <el-descriptions-item :model="form">
                <template slot="label">
                  <i class="el-icon-user"></i>
                  收货人
                </template>
                {{ form.receiveMan }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-mobile-phone"></i>
                  收货人电话
                </template>
                {{ form.receiveMobile }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-office-building"></i>
                  收货地址
                </template>
                {{ form.receiveAddr }}
              </el-descriptions-item>
            </el-descriptions>
          </el-tab-pane>
          <el-tab-pane label="物流信息" name="second" :model="form">
            <el-timeline>
              <el-timeline-item
                  v-for="(activity, index) in activities"
                  :key="index"
                  :icon="getStatusInfoObject(activity.orderModel).icon"
                  :color="getStatusInfoObject(activity.orderModel).rawColor"
                  type="primary"
                  size="larger">
                <span style="font-weight: bolder;font-size: larger">{{ getStatusInfoObject(activity.orderModel).name }}<br/></span>
                <div>
                  <span v-if="activity.orderModel == 1">
                    货物已提货，发往: <b>{{ activity.theOrder.receiveAddr }}</b>, &nbsp;提货日期：{{activity.theOrder.trustTime | formatDate}}
                  </span>
                  <span v-else-if="activity.orderModel == 3">
                    货物运输中，我们将尽快送达。<b>预计到达日期： {{activity.theOrder.estimatedArvtime | formatDate}}</b>
                  </span>
                  <span v-else-if="activity.orderModel == 4">
                    货物已签收，<b>签收时间: {{ activity.checkTime | formatDate }}</b>， 签收人：<b>{{activity.orderModelName}}</b>
                  </span>
                  <span v-else>
                    状态异常。 备注信息：<b>{{activity.orderModelName}}</b>
                  </span>

                  <el-button 
                   @click="delLogiItem(activity)"
                  style="margin-left: 5px;" 
                  size="mini" 
                  type="danger" 
                  icon="el-icon-delete" circle></el-button>

                </div>
              </el-timeline-item>
              <el-timeline-item size="larger" icon="iconfont icon-shoujianren"><b>【收货地址】</b>：{{ receiveRemark }}</el-timeline-item>
            </el-timeline>
          </el-tab-pane>
        </el-tabs>
      </template>
      <span slot="footer" class="dialog-footer">
  </span>
    </el-dialog>

    <el-dialog
        title="订单上传"
        :visible.sync="offlineUploadFileDialogVisible"
        :close-on-click-modal="false"
        :before-close="handleOfflineExcelDialogClose"
        width="50%"
        center>
      <el-upload
          class="upload-demo"
          ref="uploadOffline"
          :action="upLoadUrlOffline"
          :on-success="onSuccessOffline"
          :file-list="fileListOffline"
          :auto-upload="false" style="margin-left: 250px">
        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
        <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUploadOffline">上传到服务器</el-button>
        <div slot="tip" class="el-upload__tip">只支持提供的模板格式导入信息</div>
      </el-upload>
    </el-dialog>


    <el-dialog
        title="提示"
        :visible.sync="uploadFileDialogVisible"
        :close-on-click-modal="false"
        :before-close="handleExcelDialogClose"
        width="50%"
        center>
      <el-upload
          class="upload-demo"
          ref="upload"
          :action="upLoadUrl"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :on-success="onSuccess"
          :on-error="submitUploadError"
          :file-list="fileList"
          :auto-upload="false" style="margin-left: 250px">
        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
        <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button>
        <div slot="tip" class="el-upload__tip">只支持提供的模板格式导入信息</div>
      </el-upload>
    </el-dialog>

    <el-dialog
        title="修改物流状态"
        :visible.sync="updateCenterDialogVisible"
        width="30%"
        center>
      <el-form ref="form" :model="updateForm" label-width="80px">
        <el-form-item prop="code" label="运单号">
          <el-input v-model="updateForm.code" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item prop="orderModel" label="运单状态">
          <el-select v-model="updateForm.orderModel" placeholder="运单状态" >
            <el-option v-for="item in orderModels" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="memo" label="备注信息">
          <el-input v-model="updateForm.memo"></el-input>
        </el-form-item>
        <el-form-item prop="estimatedArvtime" label="签收日期" v-if="updateForm.orderModel == 4">
          <el-date-picker v-model="updateForm.estimatedArvtime" type="date" placeholder="选择签收日期"></el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="cancelForm">取 消</el-button>
    <el-button type="primary" @click="updateOrderStatus">确 定</el-button>
  </span>
    </el-dialog>

  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: "OrderManagerOffline",
  props: {
    getTable: {
      type: Function,
      require: true
    },
  },
  filters: {
    formatDate(value) {
      return value ? moment(value).format('YYYY-MM-DD') : '';
    },
    formatDateTime(value) {
      return value ? moment(value).format('YYYY-MM-DD HH:mm') : '';
    }
  },
  data() {
    return {
      activities: [{
        orderModelStatus: '支持使用图标',
        timestamp: '2018-04-12 20:46',
        size: 'large',
        type: 'primary',
        icon: 'el-icon-more'
      }, {
        orderModelStatus: '支持自定义颜色',
        timestamp: '2018-04-03 20:46',
        type: 'primary',
      }, {
        orderModelStatus: '支持自定义尺寸',
        timestamp: '2018-04-03 20:46',
        size: 'large',
        type: 'primary'
      }, {
        orderModelStatus: '默认样式的节点',
        timestamp: '2018-04-03 20:46'
      }],

      upLoadUrlOffline: this.$httpUrl + '/kkl/tms/webchat/offlineorders/importOfflineOrder',
      upLoadUrl: this.$httpUrl + '/kkl/tms/webchat/orders/import',
      activeStep: '1',//当前激活的步骤索
      steps: [
        {orderModelStatus: '步骤一', orderModelName: '这是步骤一的描述'},
        {orderModelStatus: '步骤二', orderModelName: '这是步骤二的描述'},
        {orderModelStatus: '步骤三', orderModelName: '这是步骤三的描述'}
      ],
      receiveRemark: '',
      fileList: [],
      fileListOffline: [],
      tableData: [],
      pageSize: 5,
      pageNum: 1,
      total: 0,
      orderModel: '',
      orderPicture: '',
      startTime: '',
      endTime: '',
      activeName: 'first',
      codeQry: '',
      size: '',
      orderModels: [{
        value: '0',
        label: '异常'
      }, {
        value: '1',
        label: '已提货'
      }, {
        value: '3',
        label: '运输中'
      }, {
        value: '4',
        label: '已签收'
      }],
      centerDialogVisible: false,
      updateCenterDialogVisible: false,
      uploadFileDialogVisible: false,
      offlineUploadFileDialogVisible: false,
      form: {
        code: '',
        goodsName: '',
        num: '',
        trustMan: '',
        trustCompany: '',
        trustMobile: '',
        trustAddr: '',
        trustTime: '',
        receiveAddr: '',
        receiveMan: '',
        receiveMobile: '',
        receiveCompany: '',
        arrivalTime: '',
        estimatedArvtime: ''
      },

      updateForm: {
        id: '',
        code: '',
        orderModel: '',
        memo: '',
        arrivalTime: '',
        estimatedArvtime: ''
      }
    }
  },
  computed: {
    currentPath() {
      return this.$route.path; // 获取当前路由的路径
    },
    currentParams() {
      return this.$route.params; // 获取路由参数
    }
  },
  methods: {

    getStatusInfoObject(code) {
      const statusCodes = {
        0: {name:'异常', color:'danger', icon:'iconfont icon-yichangwuliu', rawColor:'#F56C6C'},
        1: {name:'已提货', color:'primary', icon:'iconfont icon-baoguo_fahuo_o', rawColor:'#409EFF'},
        3: {name:'运输中', color:'warning', icon:'iconfont icon-yunshuzhongwuliu', rawColor:'#E6A23C'},
        4: {name:'已签收', color:'success', icon:'iconfont icon-yiqianshou', rawColor:'#67C23A'},
      };
      return statusCodes[code] || {name:'未知状态', color:'danger', icon:'iconfont icon-yichangwuliu', rawColor:'red'};
    },

    cancelForm() {
      // this.$refs.form.resetFields();
      this.updateCenterDialogVisible = false
    },
    resetForm() {
      // this.$refs.form.resetFields();
    },
    exportExcelOffline() {
      window.open(this.$httpUrl + '/kkl/tms/webchat/offlineorders/exportOfflineOrder')
    },
    exportExcel() {
      window.open(this.$httpUrl + '/kkl/tms/webchat/orders/export')
    },
    handleOfflineExcelDialogClose(done) {
      this.$confirm('确认关闭？')
          .then(res => {
            this.$refs.uploadOffline.clearFiles() //清除上传文件对象
            done();
            console.log(res)
          })
          .catch(res => {
            console.log(res)
          });
    },
    handleExcelDialogClose(done) {
      this.$confirm('确认关闭？')
          .then(res => {
            this.$refs.upload.clearFiles() //清除上传文件对象
            done();
            console.log(res)
          })
          .catch(res => {
            console.log(res)
          });
    },
    onSuccessOffline(res, file, fileList) {
      //显示添加成功的条数 并刷新
      this.$refs.uploadOffline.clearFiles() //清除上传文件对象
      this.offlineUploadFileDialogVisible = false
      this.$message.success("成功导入 " + res.data.successNum + " 条订单")
      console.log(fileList, file)
      this.loadPost();
    },
    onSuccess(res, file, fileList) {
      //显示添加成功的条数 并刷新
      this.$refs.upload.clearFiles() //清除上传文件对象
      this.uploadFileDialogVisible = false
      this.$message.success("成功导入 " + res.data.successNum + " 条订单")
      console.log(fileList, file)
      this.loadPost();
    },
    submitUpload() {
      this.$refs.upload.submit();
    },
    submitUploadOffline() {
      this.$refs.uploadOffline.submit();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    submitUploadSuccess() {
      this.getTable();
    },
    submitUploadError() {

    },
    updateStatus(row) {
      console.log(row)
      this.updateForm.code = row.code;
      this.updateForm.orderModel = row.orderModel;
      this.updateForm.memo = row.memo;
      this.updateForm.arrivalTime = row.arrivalTime;
      this.updateForm.estimatedArvtime = row.estimatedArvtime;
      this.updateCenterDialogVisible = true
    },
    delStatus(row) {

      console.log(row)
      let theCode = row.code;

      this.$confirm('是否删除这条运单记录?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          
          this.$axios.post(
              this.$httpUrl + '/kkl/tms/webchat/offlineorders/delOrder', 
              {code: theCode}
          )
          .then((response) => {
            if(response.data.code == 0){
              this.$message({
                message: '删除成功',
                type: 'success'
              });              
            }else {
              this.$message({
                message: '删除失败',
                type: 'warning'
              });
            }
            this.loadPost();
          })
          .catch((err) => {
            console.log(err)
          });

        }).catch(() => {
          // 点击了取消按钮  
        });
    },

    delLogiItem(theLogiItem) {

      console.log(theLogiItem);
      let theLogiId = theLogiItem.id;
      let theCode = theLogiItem.code;
     
      this.$confirm('确认删除这条物流记录?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {

          this.$axios.post(
              this.$httpUrl + '/kkl/tms/webchat/offlineorders/delLogiItem', {id: theLogiId, code: theCode}
          )
          .then((response) => {
            if(response.data.code == 0){
              this.$message({
                message: '删除成功',
                type: 'success'
              });              
            }else {
              this.$message({
                message: '删除失败',
                type: 'warning'
              });
            }
            this.queryOrderDetail(theLogiItem);
            this.loadPost();
          })
          .catch((err) => {
            console.log(err)
          });

        }).catch(() => {
                   
        });
    },

    orderDetail(row) {
      this.centerDialogVisible = true
      this.form.code = row.code;
      this.form.goodsName = row.goodsName;
      this.form.num = row.num;
      this.form.trustMan = row.trustMan;
      this.form.trustCompany = row.trustCompany;
      this.form.trustMobile = row.trustMobile;
      this.form.trustAddr = row.trustAddr;
      this.form.trustTime = row.trustTime;
      this.form.estimatedArvtime = row.estimatedArvtime;
      this.form.receiveAddr = row.receiveAddr;
      this.form.receiveMan = row.receiveMan;
      this.form.receiveMobile = row.receiveMobile;
      this.form.arrivalTime = row.arrivalTime;
      this.form.receiveCompany = row.receiveCompany;
      this.queryOrderDetail(row);
    },
    queryOrderDetail(row) {
      console.log('=====' + row)
      this.$axios.get(this.$httpUrl + '/kkl/tms/webchat/orders/queryOrderDetail?code=' + row.code).then(res => res.data).then(res => {
        if (res.code == 200) {
          res.data.orderLogistics.forEach(item => {
            item.theOrder =  { ...row };
          });
          this.steps = res.data.orderLogistics
          this.activities = res.data.orderLogistics
          this.receiveRemark = res.data.receiveAddr
        }
      })
    },
    updateOrderStatus() {
      this.$axios.post(this.$httpUrl + '/kkl/tms/webchat/offlineorders/updateOrderStatus', this.updateForm).then(res => res.data).then(res => {
        if (res.code == 200) {
          this.updateCenterDialogVisible = false
          this.$message({
            message: '修改成功',
            type: 'success'
          });
          this.pageNum = 1;
          this.loadPost();
        } else {
          this.$message({
            message: '修改失败',
            type: 'error'
          });
        }
      })
    },
    handleClick(key, keyPath) {
      console.log(key, keyPath);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val}条`)
      this.pageSize = val
      this.pageNum = 1
      this.loadPost()
    },
    handleCurrentChange(val) {
      console.log(`当前页:${val}`)
      this.pageNum = val
      this.loadPost()
    },
    resertQuery() {
      this.orderModel = '';
      this.codeQry = '';
    },
    queryList() {
      this.pageNum = 1;
      this.loadPost();
    },
    loadPost() {
      this.$axios.post(this.$httpUrl + '/kkl/tms/webchat/offlineorders/getOrderPageList', {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        param: {
          orderModel: this.orderModel,
          code: this.codeQry,
          startTime: this.startTime,
          endTime: this.endTime,
        }
      }).then(res => res.data).then(res => {
        res.data.records.forEach(item => {
          item.trustTime = this.$formatDate(item.trustTime);
          item.arrivalTime = this.$formatDate(item.arrivalTime);
          item.estimatedArvtime = this.$formatDate(item.estimatedArvtime);
        });
        this.tableData = res.data.records
        this.total = res.data.total
      })
    }
  },
  beforeMount() {
    this.loadPost();
  }
  ,
}
</script>

<style scoped>


</style>